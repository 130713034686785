import request from '@/utils/request';
import requesthttps from '@/utils/requesthttps';
// import request2 from '@/utils/request2';
import requesthttpswww from '@/utils/requesthttpswww';
// import qs from 'qs';
//总览获取年级列表
export function getOverviewGradeList(data) {
  return requesthttpswww({
    url: '/userPlant-web/api/console/grade/queryListByPosition',
    method: 'post',
    data: data,
  });
}
// 总览获取班级列表;
export function getOverviewClassList(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/class/queryList',
    method: 'post',
    data,
  });
}
//总览获取学校的所有mac /userPlant-web/api/console/pen/queryList
export function getSchoolMacList(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/pen/queryList',
    method: 'post',
    data,
  });
}
//总览获取 按照班级id获取学生的mac列表
export function classIdGetMacList(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/class/queryUserListByClassId',
    method: 'post',
    data,
  });
}

export function getjiaoxueliebiao(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/class/queryUserListByClassId',
    method: 'post',
    data,
  });
}
//新数据接口
export function getPageWorks(data) {
  return requesthttps({
    url: '/userPlant-web/api/console/plan/getWorksNew',
    method: 'post',
    data,
  });
}
//老数据接口
// export function getPageWorks(data){
//     return requesthttps({
//         url:'/userPlant-web/api/console/plan/getPageWorks',
//         method:'post',
//         data
//     })
// }
export function getsetId(data) {
  return request({
    url: '/getsetId',
    method: 'post',
    data,
  });
}
export function getexaminations(data) {
  return request({
    url: '/getexaminations',
    method: 'post',
    data,
  });
}
