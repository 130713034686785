import axios from 'axios';
import { Message } from 'element-ui';
import apis from './https';
import qs from 'qs';
import baseJs from './base/base.js';
axios.defaults.headers.post['Content-Type'] =
  'application/x-www-form-urlencoded';
const service = axios.create({
  baseURL: apis, // url = base url + request url
});

service.interceptors.request.use(
  (config) => {
    if (JSON.parse(localStorage.getItem('teachuserToken'))) {
      config.headers['userToken'] = JSON.parse(
        localStorage.getItem('teachuserToken')
      );
      config.data = qs.stringify(config.data); // 转为formdata数据格式
    } else {
      Message({
        message: 'token失效，请重新登录',
        type: 'error',
        duration: 5 * 1000,
      });
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    //拦截器没看懂   要传进来data,url，请求方式
    let data = baseJs.SignIn({ loginData: response, token: 'teachuserToken' });
    return data;
  },
  (error) => {
    console.log(error);
    // Message({
    //   message: error.response.data.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error);
  }
);

export default service;
